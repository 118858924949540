import React from 'react';
import buddyimg from '../../Assets/homeImages/jackport.png';
import { TbCircleDotFilled } from "react-icons/tb";

const AboutbetBuddy = () => {
    return (
        <div className='flex flex-col md:flex-row px-4 md:px-12 lg:px-16 justify-between space-y-6 md:space-y-0 md:space-x-6 lg:space-x-8'>
            <div className='w-full md:w-[50%] mb-4 md:mb-0'>
                <img src={buddyimg} alt='buddyimg' className='w-full h-auto object-cover' />
            </div>
            <div className='w-full md:w-[50%] flex items-center justify-center'>
                <div className='text-white space-y-6 md:space-y-8'>
                    <h1 className='text-3xl sm:text-4xl md:text-5xl lg:text-6xl tracking-wider text-center md:text-left'>
                        About GROW BET
                    </h1>
                    <p className='tracking-wider text-sm sm:text-base md:text-lg text-justify'>
                        Grow Bet is a dynamic platform designed to empower users with smart betting strategies, offering insights, tools, and real-time data. It enhances the betting experience with a focus on growth, profitability, and responsible gaming practices.
                    </p>
                    <ul className='space-y-3 md:space-y-4 tracking-wider text-sm sm:text-base md:text-lg'>
                        <li className='flex items-center'>
                            <TbCircleDotFilled size={22} className='mr-3 text-red-700' />
                            Grow Bet empowers users with smart strategies and real insights.
                        </li>
                        <li className='flex items-center'>
                            <TbCircleDotFilled size={22} className='mr-3 text-green-700' />
                            Mommodo odio ut cras, mauris nihilit magna aliquetali montes.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default AboutbetBuddy;
