import React from 'react'
import connect from '../../Assets/homeImages/connect.png'

const Connect = () => {
    return (
        <div
            className='pb-6 mt-6 flex items-center justify-center flex-col rounded-3xl p-4 sm:p-8 mx-4 sm:mx-16 py-3'
            style={{
                backgroundImage: `url(${connect})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            }}
        >
            <h1 className='text-center text-white text-xl sm:text-2xl md:text-4xl tracking-widest'>
                “To get exclusive Benefits, Connect with us”
            </h1>
            <div className='w-full sm:w-[25rem] md:w-[30rem] lg:w-[35rem] relative'>
                <input
                    type='text'
                    placeholder='Enter your email'
                    className='w-full py-2 px-4 sm:py-3 rounded-md mt-5 sm:mt-9 border-red-500 border-2'
                />
            </div>
        </div>
    )
}

export default Connect
