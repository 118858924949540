import React from 'react'
// import img1 from '../../Assets/Home/people1.png'
// import img2 from '../../Assets/Home/people2.png'
// import img3 from '../../Assets/Home/people3.png'
import img1 from '../../Assets/homeImages/winer1.png'
import img2 from '../../Assets/homeImages/winer2.png'
import img3 from '../../Assets/homeImages/winer3.png'
const LeaderBoard = () => {
    return (
        <div className='px-4'>
            <h1 className='text-center text-white text-3xl md:text-5xl tracking-wide pt-14'>LEADERBOARD</h1>
            <div className='flex flex-col md:flex-row gap-10 px-4 md:px-16'>
                <div className='w-full md:w-[402px] h-[20rem] md:h-[20rem] bg-black  rounded-md border-white border-2 flex-col mt-14 mx-auto'>
                    <img className='w-[96px] h-[95px] mx-auto mt-3' src={img1} alt='img' />
                    <div className='mt-4 space-y-4'>
                        <h1 className='text-2xl md:text-3xl text-center text-white tracking-wide'>Hera Rahman</h1>
                        <h1 className='text-2xl md:text-3xl text-center text-white tracking-wide'>$272,243</h1>
                        <p className='text-white text-center'>BET ID : MN 2356 8962 563</p>
                    </div>
                </div>
                <div className='w-full md:w-[402px] h-[20rem] md:h-[20rem] bg-black  rounded-md border-white border-2 flex-col mt-14 mx-auto'>
                <img className='w-[96px] h-[95px] mx-auto mt-3' src={img2} alt='img' />
                    <div className='mt-4 space-y-4'>
                        <h1 className='text-2xl md:text-3xl text-center text-white tracking-wide'>Hera Rahman</h1>
                        <h1 className='text-2xl md:text-3xl text-center text-white tracking-wide'>$272,243</h1>
                        <p className='text-white text-center'>BET ID : MN 2356 8962 563</p>
                    </div>
                </div>
                <div className='w-full md:w-[402px] h-[20rem] md:h-[20rem] bg-black rounded-md border-white border-2 flex-col mt-14 mx-auto'>
                <img className='w-[96px] h-[95px] mx-auto mt-3' src={img3} alt='img' />
                    <div className='mt-4 space-y-4'>
                        <h1 className='text-2xl md:text-3xl text-center text-white tracking-wide'>Hera Rahman</h1>
                        <h1 className='text-2xl md:text-3xl text-center text-white tracking-wide'>$272,243</h1>
                        <p className='text-white text-center'>BET ID : MN 2356 8962 563</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeaderBoard
