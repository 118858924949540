import React from 'react'
import heroimg from '../../Assets/homeImages/topBanner.png'

const HeroSection = () => {
    return (
        <React.Fragment>
            <div>
                <img src={heroimg} alt='img' className='w-[100%] lg:h-[98vh]' />
            </div>
        </React.Fragment>
    )
}
export default HeroSection
