import React from 'react'
import Cricket from '../../Assets/homeImages/Cricket.png'
import football from '../../Assets/homeImages/FOOTBALL.png'
import Tennis from '../../Assets/homeImages/TENNIS.png'
import Poker from '../../Assets/homeImages/POKER.png'
import Roulette from '../../Assets/homeImages/ROULETTE.png'
import Teenpati from '../../Assets/homeImages/TEENPATI.png'
import Casino from '../../Assets/homeImages/CASINO.png'
import SpinWheel from '../../Assets/homeImages/SPINWHEEL.png'

const OurGames = () => {
    return (
        <React.Fragment>
            <h1 className='text-center text-white text-5xl tracking-wide mt-8'>
                OUR GAMES
            </h1>
            <div className='grid px-8 sm:px-12 lg:px-16 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 py-8 bg-[#0E0E0E]'>
                <img src={Cricket} alt='Cricket' className='w-full object-cover' />
                <img src={football} alt='Football' className='w-full object-cover' />
                <img src={Tennis} alt='Tennis' className='w-full object-cover' />
                <img src={Poker} alt='Poker' className='w-full object-cover' />
                <img src={Roulette} alt='Roulette' className='w-full object-cover' />
                <img src={Teenpati} alt='Teenpati' className='w-full object-cover' />
                <img src={Casino} alt='Casino' className='w-full object-cover' />
                <img src={SpinWheel} alt='Spin Wheel' className='w-full object-cover' />
            </div>
        </React.Fragment>
    )
}

export default OurGames