import React from "react";
import "./Marquee.css";

const words = ["Jackpot Casino Games. ♠️", "Jackpot Casino Games. ♠️"];

const Marquee = () => {
  return (
    <div className="marquee-container mt-10">
      <div className="marquee-content">
        {words.map((word, index) => (
          <span
            key={index}
            className={`font-Space font-semibold tracking-[-6px] marquee-item ${
              index % 2 === 0 ? "solid-text" : "border-text"
            }`}
          >
            {word}
          </span>
        ))}
        {words.map((word, index) => (
          <span
            key={`dup-${index}`}
            className={`font-Space font-semibold tracking-[-6px] leading-[6rem] marquee-item ${
              index % 2 === 0 ? "solid-text" : "border-text"
            }`}
          >
            {word}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Marquee;