import React from 'react';
import redbg from '../../Assets/homeImages/redbg.png';
import img1 from '../../Assets/homeImages/img1.png';
import gamefeatures from '../../Assets/homeImages/gamefeatures.png';
import Gameproviders from '../../Assets/homeImages/Gameproviders.png';
import tablegame from '../../Assets/homeImages/Tablegames.png';
import jackportgame from '../../Assets/homeImages/Jackpotgames.png';
import icon from '../../Assets/homeImages/Icon.png';

const CanEnjoy = () => {
  return (
    <React.Fragment>
      <div
        className='flex items-center justify-center flex-col h-[25rem] relative md:h-[30rem] my-10'
        style={{
          backgroundImage: `url(${redbg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left",
        }}
      >
        <img className='absolute -top-20 md:-top-28 w-32 md:w-40' src={img1} alt='img' />
        <img className='absolute left-16 top-6 w-28 md:w-36' src={jackportgame} alt='jackportgame' />
        <img className='absolute right-16 top-6 w-28 md:w-36' src={tablegame} alt='tablegame' />
        <img className='absolute left-16 bottom-6 w-28 md:w-36' src={Gameproviders} alt='Gameproviders' />
        <img className='absolute right-16 bottom-6 w-28 md:w-36' src={gamefeatures} alt='gamefeatures' />
        <h1 className='text-white text-3xl md:text-7xl text-center tracking-wider mt-2'>You can enjoy your</h1>
        <h1 className='text-white text-3xl md:text-7xl text-center tracking-wider mt-2'>favorite slot games</h1>
        <h1 className='text-white text-3xl md:text-7xl text-center tracking-wider mt-2'>with us</h1>
      </div>

      <div className='flex flex-wrap justify-around mb-10 space-y-6 md:space-y-0 px-10'>
        <div className='flex w-full sm:w-auto py-6 text-white space-x-3 md:space-x-5 items-center'>
          <img className='w-12 md:w-16' src={icon} alt='icon' />
          <div>
            <h1 className='text-xl md:text-2xl tracking-widest'>$15,550</h1>
            <p className='text-md md:text-xl text-[#02C4F2] tracking-widest'>Paid Out Prize in Total</p>
          </div>
        </div>
        <div className='flex w-full sm:w-auto py-6 text-white space-x-3 md:space-x-5 items-center'>
          <img className='w-12 md:w-16' src={icon} alt='icon' />
          <div>
            <h1 className='text-xl md:text-2xl tracking-widest'>700+</h1>
            <p className='text-md md:text-xl text-[#02C4F2] tracking-widest'>Winners</p>
          </div>
        </div>
        <div className='flex w-full sm:w-auto py-6 text-white space-x-3 md:space-x-5 items-center'>
          <img className='w-12 md:w-16' src={icon} alt='icon' />
          <div>
            <h1 className='text-xl md:text-2xl tracking-widest'>100+</h1>
            <p className='text-md md:text-xl text-[#02C4F2] tracking-widest'>Active Users</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CanEnjoy;
