import React from 'react'
import img1 from '../../Assets/homeImages/play.png'

const Play = () => {
    return (
        <React.Fragment>
            <div className='flex flex-wrap justify-center gap-10 mt-10 mb-10 px-10' id='element'>
                {/* Card 1 */}
                <div className='bg-gradient-to-b from-purple-500 via-pink-500 to-red-500 p-[2px] rounded-md'>
                    <div className='bg-[#171717] text-white w-full min-w-[18rem] sm:w-[18rem] md:w-[20rem] py-24 rounded-md flex items-center justify-center'>
                        <h1 className='tracking-widest text-center text-lg sm:text-xl md:text-2xl leading-tight'>
                            Refill & <br /> Withdraw
                        </h1>
                    </div>
                </div>

                {/* Card 2 */}
                <div className='bg-gradient-to-b from-blue-500 via-green-500 to-yellow-500 p-[2px] rounded-md'>
                    <div className='bg-[#171717] text-white w-full min-w-[18rem] sm:w-[18rem] md:w-[20rem] py-24 rounded-md flex items-center justify-center'>
                        <h1 className='tracking-widest text-center text-lg sm:text-xl md:text-2xl leading-tight'>
                            10m+<br /> Active Users
                        </h1>
                    </div>
                </div>

                {/* Card 3 */}
                <div className='bg-gradient-to-b from-teal-500 via-cyan-500 to-blue-500 p-[2px] rounded-md'>
                    <div className='bg-[#171717] text-white w-full min-w-[18rem] sm:w-[18rem] md:w-[20rem] py-24 rounded-md flex items-center justify-center'>
                        <h1 className='tracking-widest text-center text-lg sm:text-xl md:text-2xl leading-tight'>
                            Welcome Bonus <br /> 10 %
                        </h1>
                    </div>
                </div>

                {/* Card 4 */}
                <div className='bg-gradient-to-b from-indigo-500 via-purple-500 to-pink-500 p-[2px] rounded-md'>
                    <div className='bg-[#171717] text-white w-full min-w-[18rem] sm:w-[18rem] md:w-[20rem] py-24 rounded-md flex items-center justify-center'>
                        <h1 className='tracking-widest text-center text-lg sm:text-xl md:text-2xl leading-tight'>
                            Trusted <br /> Platform
                        </h1>
                    </div>
                </div>

                {/* Card 5 */}
                <div className='bg-gradient-to-b from-red-500 via-yellow-500 to-green-500 p-[2px] rounded-md'>
                    <div className='bg-[#171717] text-white w-full min-w-[18rem] sm:w-[18rem] md:w-[20rem] py-24 rounded-md flex items-center justify-center'>
                        <h1 className='tracking-widest text-center text-lg sm:text-xl md:text-2xl leading-tight'>
                            24*7 <br /> Customer Support
                        </h1>
                    </div>
                </div>
            </div>

            <div className='w-full'>
                <img className='w-full' src={img1} alt='img'/>
            </div>
        </React.Fragment>
    )
}

export default Play
