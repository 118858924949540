import React, { useState } from 'react';
import { HiOutlineMenuAlt3, HiX } from 'react-icons/hi';
import logo from '../../Assets/logo/growbet.png';
import { BiMobileVibration } from "react-icons/bi";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleScroll = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    setMenuOpen(false); // Close the menu after click in mobile
  };

  return (
    <React.Fragment>
      {/* Top Section with Phone Number */}
      <div className='flex justify-end items-center bg-gradient-to-l from-[#0c2231] to-[#020202] pt-2 pb-1 px-4 sm:px-6 lg:px-8 text-[#d6d2d2] font-normal text-sm tracking-wider'>
        <div>
          <BiMobileVibration size={20} className='mr-2' />
        </div>
        <div>
          +91 9983308300
        </div>
      </div>

      {/* Navbar Header */}
      <header className="bg-gradient-to-r from-[#360208] to-[#020202] text-white py-2 tracking-wider">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative flex items-center justify-between h-16">
          {/* Left Section - Links */}
          <div className="hidden md:flex space-x-6">
            <button
              className="hover:text-gray-200 transition duration-200 tracking-wider"
              onClick={() => handleScroll('home')}
            >
              Home
            </button>
            <button
              className="hover:text-gray-200 transition duration-200 tracking-wider"
              onClick={() => handleScroll('games')}
            >
              Games
            </button>
            <button
              className="hover:text-gray-200 transition duration-200 tracking-wider"
              onClick={() => handleScroll('about')}
            >
              About
            </button>
            <button
              className="hover:text-gray-200 transition duration-200 tracking-wider"
              onClick={() => handleScroll('leaderboard')}
            >
              Leaderboard
            </button>
          </div>

          {/* Middle Section - Logo */}
          <div className="absolute left-1/2 transform -translate-x-1/2 my-2">
            <img src={logo} alt="Logo" className="h-16 object-contain w-auto" />
          </div>

          {/* Right Section - Login Button */}
          <div className="hidden md:block">
            <button className="tracking-wider border-2 border-red-500 text-white hover:bg-red-500 hover:text-white transition duration-200 px-4 py-2 rounded-full">
              Login
            </button>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden flex items-center">
            <button onClick={toggleMenu}>
              {menuOpen ? (
                <HiX className="h-6 w-6" />
              ) : (
                <HiOutlineMenuAlt3 className="h-6 w-6" />
              )}
            </button>
          </div>
        </nav>

        {/* Sidebar for Mobile Menu */}
        <div
          className={`fixed top-0 left-0 h-full w-64 bg-gradient-to-r from-gray-800 to-gray-900 text-white transform ${menuOpen ? 'translate-x-0' : '-translate-x-full'
            } transition-transform duration-300 ease-in-out z-50`}
        >
          <div className="flex justify-between items-center p-4">
            <img src={logo} alt="Logo" className="h-10 w-auto" />
            <button onClick={toggleMenu} className="focus:outline-none">
              <HiX className="h-6 w-6" />
            </button>
          </div>
          <div className="flex flex-col mt-4 space-y-4 text-center">
            <button
              className="hover:text-gray-400 transition duration-200 tracking-wider"
              onClick={() => handleScroll('home')}
            >
              Home
            </button>
            <button
              className="hover:text-gray-400 transition duration-200 tracking-wider"
              onClick={() => handleScroll('games')}
            >
              Games
            </button>
            <button
              className="hover:text-gray-400 transition duration-200 tracking-wider"
              onClick={() => handleScroll('about')}
            >
              About
            </button>
            <button
              className="hover:text-gray-400 transition duration-200 tracking-wider"
              onClick={() => handleScroll('leaderboard')}
            >
              Leaderboard
            </button>

            <button
              className="border-2 border-red-500 text-white hover:bg-red-500 hover:text-white transition duration-200 tracking-wider px-4 py-2 rounded-full"
              onClick={() => handleScroll('login')}
            >
              Login
            </button>
          </div>
        </div>

        {/* Overlay for Mobile Menu */}
        {menuOpen && (
          <div
            className="fixed inset-0 bg-black opacity-50 z-40"
            onClick={toggleMenu}
          />
        )}
      </header>
    </React.Fragment>
  );
};

export default Navbar;