import React from 'react';
import HeroSection from '../../Components/Home/HeroSection';
import WhatsappComponent from '../../Components/meessages/WhatsappComponent';
import Telegram from '../../Components/meessages/Telegram';
import OurGames from '../../Components/Home/OurGames';
import AboutbetBuddy from '../../Components/Home/AboutbetBuddy';
import Play from '../../Components/Home/Play';
import LeaderBoard from '../../Components/Home/LeaderBoard';
import Connect from '../../Components/Home/Connect';
import CanEnjoy from '../../Components/Home/CanEnjoy';
import Marquee from '../../Components/Home/Marquee';

const Home = () => {
  return (
    <div className='bg-[#0E0E0E]'>
      <section id="home">
        <HeroSection />
      </section>

      <section id="games">
        <OurGames />
        <CanEnjoy />
      </section>

      <section id="about">
        <AboutbetBuddy />
        <Marquee />
        <Play />
      </section>

      <section id="leaderboard">
        <LeaderBoard />
        <Connect />
      </section>

      <WhatsappComponent />
      {/* <Telegram/> */}
    </div>
  )
}

export default Home
